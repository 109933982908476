.cursor-menu {
  position: relative;
  float: right;
  left: 0px;
  cursor: pointer;
  padding: 8px;
  color: rgb(255, 255, 255);
  /* background-color: rgba(255, 255, 255, 0.145);
  border-radius: 5px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.645); */
}

.header-bg {
  background-color: #DD271C;
}
.user-label {
  position: fixed;
  top: 0px;
  right: 15px;
}

.bg-ariztia {
  background-color: #DD271C;
}
td {
  border-bottom:1px solid black;
}
th {
  border-bottom:1px solid black;
}
.btn-edit-data {
  position: absolute;
  top: 70px;
  right: 10px
}
.filter-planta {
  position: absolute;
  background-color: #fff;
  top: 30px;
  left: 25px;
}
.activado {
  background-color: rgba(0, 0, 0, 0.2);
}

.highcharts-credits {
  display: none;
}